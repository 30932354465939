import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "row w-100" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 mb-2"
}
const _hoisted_3 = ["src", "id"]
const _hoisted_4 = {
  key: 1,
  class: "col-12 d-flex justify-content-center align-items-center flex-column"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_6 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = {
  key: 0,
  class: "progress"
}
const _hoisted_9 = ["aria-valuenow"]
const _hoisted_10 = {
  key: 2,
  class: "col-12"
}
const _hoisted_11 = ["id", "poster"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "col-12 d-flex justify-content-center align-items-center flex-column" }
const _hoisted_14 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_15 = {
  key: 3,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((!$setup.hasCordova || $setup.stateVideo === 'NotFound') && $setup.isOnline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("iframe", {
            style: {"width":"100%","height":"100%","overflow":"hidden"},
            type: "text/html",
            src: $props.onlineVideoUrl,
            width: "100%",
            height: "100%",
            allowfullscreen: "",
            allow: "autoplay",
            id: 'iframe-' + $setup.videoName
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (
        $setup.hasCordova &&
        ($setup.stateVideo === 'NotFound' || $setup.stateVideo === 'Downloaded') &&
        $setup.isOnline
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          ($setup.videoProgress === -1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.downloadVideo && $setup.downloadVideo(...args)))
              }, [
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_inline_svg, {
                    src: require('@/../public/media/icons/duotune/arrows/arr044.svg')
                  }, null, 8, ["src"])
                ]),
                _createTextVNode(" Télécharger (accès hors ligne) ")
              ]))
            : _createCommentVNode("", true),
          ($setup.videoProgress !== -1 && $setup.videoProgress !== 100)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.stopDownloadVideo && $setup.stopDownloadVideo(...args)))
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, {
                    src: require('@/../public/media/icons/duotune/general/gen034.svg')
                  }, null, 8, ["src"])
                ]),
                _createTextVNode(" Arrêter le téléchargement ")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            ($setup.videoProgress !== -1 && $setup.videoProgress !== 100)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "progress-bar progress-bar-striped progress-bar-animated bg-primary",
                    role: "progressbar",
                    "aria-valuenow": $setup.videoProgress,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100",
                    style: _normalizeStyle('width: ' + $setup.videoProgress + '%')
                  }, null, 12, _hoisted_9)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (
        $setup.hasCordova && ($setup.stateVideo === 'Found' || $setup.stateVideo === 'Downloaded')
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("video", {
            controls: "",
            width: "250",
            id: 'video-' + $setup.videoName,
            poster: 
          require('@/../public/media/logos/logo_AgriBEST_300dpi_color.png')
        ,
            class: "w-100"
          }, [
            _createElementVNode("source", {
              src: $setup.videoSrc,
              type: "video/mp4"
            }, null, 8, _hoisted_12)
          ], 8, _hoisted_11),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_component_inline_svg, {
                src: require('@/../public/media/icons/duotune/general/gen037.svg')
              }, null, 8, ["src"])
            ]),
            _createTextVNode(" Disponible hors ligne ")
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.hasCordova && $setup.stateVideo === 'NotFound' && !$setup.isOnline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, " Video non disponible hors ligne. A l'avenir, vous pouvez la téléchager pour la consulter lorsque vous êtes hors ligne. "))
      : _createCommentVNode("", true)
  ]))
}