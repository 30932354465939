
import { defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import VideoComponent from "@/components/global/VideoComponent.vue";
import { moduleVideo } from "@/store/modules/VideosModule";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "WhatIsAgriBEST",
  components: {
    VideoComponent,
    CardTitleJaya,
  },
  setup() {
    const store = useStore();

    let moduleVideos = ref<Array<moduleVideo>>([]);

    onMounted(() => {
      setCurrentPageTitle("AgriBEST ?");
      store.dispatch(Actions.FETCH_MODULE_VIDEOS).then(() => {
        moduleVideos.value = store.getters.getModuleVideos
          .filter((e) => {
            return e.module_name === 2;
          })
          .sort((a, b) => {
            return a.order < b.order ? -1 : 1;
          });
      });
    });

    return {
      moduleVideos,
    };
  },
});
