
import { computed, onMounted, ref, watch } from "vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
declare let window: any;

export default {
  name: "VideoComponent",
  props: {
    videoUrl: String,
    onlineVideoUrl: String,
    pause: { type: Boolean, required: false },
  },

  setup(props) {
    watch(
      () => props.pause,
      (pauseValue) => {
        if (pauseValue) {
          const eltIframe = document.getElementById(
            "iframe-" + videoName
          ) as HTMLVideoElement;
          if (eltIframe && eltIframe.src) {
            eltIframe.src = "blank_";
            setTimeout(() => {
              eltIframe.src = props.onlineVideoUrl;
            }, 50);
          }
        }
        const eltVideo = document.getElementById(
          "video-" + videoName
        ) as HTMLVideoElement;
        if (eltVideo) {
          eltVideo.pause();
        }
      }
    );
    const videoName = props.videoUrl.split("?token")[0].split("/").slice(-1)[0];
    let stateVideo = ref<string>("NotChecked");
    let videoSrc = ref<string>("");
    const logDirectory = function (logDirectoryEntry) {
      logDirectoryEntry.getFile(
        videoName,
        { create: false },
        function () {
          window.resolveLocalFileSystemURL(
            window.cordova.file.dataDirectory + "/AgribestVideos/" + videoName,
            checkAndSetVideo,
            fileNotFoundManagement
          );
        },
        fileNotFoundManagement
      );
    };
    const fileNotFoundManagement = function () {
      stateVideo.value = "NotFound";
    };
    const checkAndSetVideo = function (fileEntry) {
      fileEntry.file(function (file) {
        stateVideo.value = "Found";
        if (window.cordova.platformId === "ios") {
          let reader = new FileReader();
          reader.onloadend = function () {
            let blob = new Blob([new Uint8Array(this.result as ArrayBuffer)], {
              type: "video/mp4",
            });
            const elt = document.getElementById("video-" + videoName);
            if (elt) {
              elt["src"] = window.URL.createObjectURL(blob);
              elt["autoplay"] = false;
            }
          };
          reader.onerror = fileNotFoundManagement;
          reader.readAsArrayBuffer(file);
        }
        if (window.cordova.platformId === "android") {
          videoSrc.value =
            window.cordova.file.dataDirectory + "/AgribestVideos/" + videoName;
        }
      });
    };
    // if window.cordova: check file state
    if (window.cordova) {
      window.resolveLocalFileSystemURL(
        window.cordova.file.dataDirectory,
        function (directoryEntry) {
          directoryEntry.getDirectory(
            "AgribestVideos",
            { create: false },
            logDirectory,
            fileNotFoundManagement
          );
        },
        fileNotFoundManagement
      );
    }
    const downloadVideo = () => {
      store
        .dispatch(Actions.DOWNLOAD_VIDEO, { url: props.videoUrl })
        .then(() => {
          window.resolveLocalFileSystemURL(
            window.cordova.file.dataDirectory,
            function (directoryEntry) {
              directoryEntry.getDirectory(
                "AgribestVideos",
                { create: false },
                logDirectory,
                fileNotFoundManagement
              );
            },
            fileNotFoundManagement
          );
        });
    };
    const stopDownloadVideo = () => {
      store.dispatch(Actions.STOP_DOWNLOAD_VIDEO, {
        name: videoName,
      });
    };
    const isOnline = computed(() => store.getters.isOnline);

    const hasCordova = computed(() => {
      return window.cordova !== undefined;
    });

    const videoProgress = computed(() => {
      const videoMatch = store.getters.currentDlVideos.filter((e) => {
        return e.name == videoName;
      });
      if (videoMatch.length) {
        return videoMatch[0].progress;
      }
      return -1;
    });
    return {
      stateVideo,
      downloadVideo,
      stopDownloadVideo,
      isOnline,
      videoProgress,
      window,
      hasCordova,
      videoName,
      videoSrc,
    };
  },
};
